
/***************************
    CUSTOM SCROLL BAR
****************************/


:root{
    --white:#FFF;
    --black:#232323;
    --lite:rgba(255,255,255,0.6);
    --gray:rgba(1,1,1,0.6);
    --dark:#3c3d3c;
    --primary:linear-gradient(145deg,#097cff,#06348a);
    --primary_dark:#021e5c;
    --primary_lite:#4a56c2;
    --secondary:#000a17;
    --default_font:'Ysabeau Infant', sans-serif;
    --title_font:'Outfit', sans-serif;
}

::-webkit-scrollbar {
    height: 12px;
    width: 8px;
    background: var(--dark);
}



::-webkit-scrollbar-corner {
    background: var(--dark);
}



/***************************
            DEFAULT
****************************/

a{
    text-decoration:none !important;
    min-width: fit-content;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
}

a, button{
    transition:0.5s;
}

em{
    font-style:normal;
    color:var(--primary_lite);
}

a, p, .btn{
    font-size:15px;
}



a, button, input, textarea, select{
    outline:none !important;
}

fieldset{
    border:0;
}



.title, .sub_title{
    font-family:var(--title_font);
    font-weight:400;
    margin:0;
}

.flex, .fixed_flex{
    display:flex;
}

.flex_content{
    width:100%;
    position:relative;
}

.padding_1x{
    padding:1rem;
}

.padding_2x{
    padding:2rem;
}

.padding_3x{
    padding:3rem;
}

.padding_4x{
    padding:4rem;
}

.big{
    font-size:3.5em;
}

.medium{
    font-size:2em;
}

.small{
    font-size:1.3em;
}

.btn{
    padding:1rem;
    border-radius:5px;
    color:var(--white);
    position:relative;
    border:0;
    text-align:center;
    
}

.btn_3{
    display:block;
    background-color:0;
    color:var(--white);
    position:relative;
    font-family:var(--default_font);
    font-weight:400;
    text-transform:uppercase;
}

.btn_3:before{
    content:"";
    border-radius:50%;
    background-color:rgba(255,255,255,0.2);
    position:absolute;
    left:0;
    top:50%;
    width:40px;
    height:40px;
    transition:0.5s;
    transform:translate(0%, -50%);
}

.btn_3:after{
    content:"\f178";
    font-family:"FontAwesome";
    margin-left:5px;
}

.btn_3:hover:before{
    border-radius:40px;
    width:100%;
}

@media (max-width:920px){
    .flex{
        flex-wrap:wrap;
    }
    
    .padding_1x, .padding_2x, .padding_3x, .padding_4x{
        padding:1rem;
    }
    
    .big{
        font-size:1.8em;
    }
    
    .medium{
        font-size:1.3em;
    }
    
    .small{
        font-size:1.1em;
    }
    
    .btn{
        padding:0.5rem 1rem;
    }
    
    a, p, .btn{
        font-size:12px;
    }
}

.slider {
    position: relative;
    width: 100%;
    height: 100vh;
}

ul{
    padding:0;
    margin:0;
}

.slider .title{
    font-weight:400;
}

.slider li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    transition: clip .7s ease-in-out, z-index 0s .7s;
    clip: rect(0, 100vw, 100vh, 100vw);
    display:flex;
    align-items:center;
    justify-content:left;
}

.slider li:nth-child(1){
    background:linear-gradient(to right, rgba(1,1,1,1) 10%, rgba(1,1,1,0.8) 51%, rgba(1,1,1,0.2) 100%), url("https://images.unsplash.com/photo-1542744095-291d1f67b221?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80");
    background-size: cover;
    background-position:top left;
}

.slider li:before{
    content:"";
    position:absolute;
    top:-20px;
    right:0;
    width:250px;
    height:250px;
    background-image:url("https://i.postimg.cc/02zcww6c/slider-shape.png");
    background-size:100% 100%;
    transition-delay: 1s;
    transform: rotate(-90deg);
    z-index:1;
}

.slider li:after{
    content:"";
    position:absolute;
    bottom:0;
    left:0;
    width:300px;
    height:300px;
    background-image:url("https://i.postimg.cc/KjKbwDfR/slider-shape-2.png");
    background-size:100% 100%;
    transform:rotate(360deg);
	transition: all 1.5s cubic-bezier(0,0,.2,1);
    z-index:1;
    background-position:center left;
}

.slider li:nth-child(2){
    background:linear-gradient(to right, rgba(1,1,1,1) 10%, rgba(1,1,1,0.8) 51%, rgba(1,1,1,0.2) 100%), url("https://images.unsplash.com/photo-1587614382346-4ec70e388b28?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80");
    background-size: cover;
    background-position:top left;
}

.slider li:nth-child(3){
    background:linear-gradient(to right, rgba(1,1,1,1) 10%, rgba(1,1,1,0.8) 51%, rgba(1,1,1,0.2) 100%), url("https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=872&q=80");
    background-size: cover;
    background-position:top left;
}

.slider article{
    width:60%;
    margin-top:4rem;
    color: #fff;
    z-index:11;
}

.slider h3 + p {
    display: inline-block;
    color: var(--lite);
    font-weight:300;
}

.slider h3, .slider h3 + p, .slider p + .btn, li:after{
    opacity: 0;
    transition: opacity .7s 0s, transform .5s .2s;
    transform: translate3d(0, 50%, 0);
}

li.current h3, li.current h3 + p, li.current p + .btn, li.current:after {
    opacity: 1;
    transition-delay: 1s;
    transform: translate3d(0, 0, 0);
}

.slider li:before{
    transition: 0.5s;
    top:-250px !important;
}

li.current:before{
    transition-delay: 1s;
    transform: rotate(-90deg);
    top:-20px !important;
}

li.current {
    z-index: 1;
    clip: rect(0, 100vw, 100vh, 0);
}

li.prev {
    clip: rect(0, 0, 100vh, 0);
}

.slider aside {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    text-align: center;
    z-index: 10;
}

.slider aside a {
    display: inline-block;
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    background-color: var(--white);
    margin: 0 0.2rem;
    transition: transform .3s;
}

.slider em{
    background: var(--primary);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

a.current_dot {
    transform: scale(1.0);
    background:var(--primary) !important;
}

@media screen and (max-width: 920px) {
    header{
        height:70vh;
        position:relative;
    }
    
    .cs-down{
        display:none;
    }

    .slider{
        height:70vh;
    }
    
    .slider article{
        width:100%;
        margin-top:2rem;
    }
    
    .slider li:nth-child(2){
        background-position:top center;
    }
    
    .slider li:nth-child(3){
        background-position:top center;
    }
    
    li.current:before{
        top:-30px;
        right:-10px;
    }
}