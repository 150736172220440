
.navbar-brand img {
  max-width: 100%;
  height: auto;
}

/* Search Input */
.input-group {
  margin-left: 20px;
}

.form-control.rounded-pill {
  border-radius: 25px;
}

/* Navigation Links */
.navbar-nav .nav-item {
  margin-right: 20px;
}

.nav-link {
  color: #0815a0;
  font-weight: 500;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #007bff;
}

/* Dropdown Menu */
.dropdown-menu {
  border: none;
  box-shadow: 3px 2px 7px rgba(6, 15, 136, 0.842);
}

.dropdown-columns {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.dropdown-columns ul {
  list-style: none;
}

.dropdown-columns ul li {
  margin-bottom: 10px;
}
.contact-bg{
  color: #000000;
  margin-bottom: 1px;
}
/* Profile Link */
.item-link {
  display: flex;
  align-items: center;
}

.item-link .fa-icon {
  margin-right: 5px;
}

/* Modals */
.modal-content {
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  background-color: #06529e;
  border-bottom: none;
}

.modal-title {
  font-weight: 600;
}

.modal-body {
  padding: 20px;
}

.nav-item a {
  color: #000000;
  font-size: 16px;
  margin-right: 15px;
}
.nav-item i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.user-bg{
  background: rgb(255, 255, 255);
  border: 2px solid black;
}
.contact-bg{
  background: #ffffff67;
}
/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .navbar-nav.ml-auto {
    margin-top: 10px;
  }
  
  .item-link {
    margin: 10px 0;
  }
}
