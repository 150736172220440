

p {
  text-align: center;


}
.shelf {
  position: relative;
  width: 30rem;
  height: 14rem;
  margin: 0 auto;
  border: 0.5rem solid #374d5b;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  perspective: 130rem;
  box-shadow: inset 0 0 2rem rgba(0, 0, 0, 0.2);
}
.door {
  position: absolute;
  width: 14.8rem;
  height: 14rem;
  display: flex;
  box-sizing: border-box;
  padding: 1rem;
  background-color: #374d5b;
  align-items: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.486);
  outline: 1px solid transparent;
  &::before {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: rgba(1, 12, 61, 0.548);
    content: "";
  }
  &.left {
    border-radius: 0 0.75rem 0.75rem 0;
    justify-content: flex-end;
    animation: 
      leftDoorOpen 3.5s ease-out forwards 1s,
      leftDoorFlap 15s linear infinite forwards 9s;
    transform-origin: 0 0 0;
  }
  &.right {
    right: 0;
    border-radius: 0.75rem 0 0 0.75rem;
    animation: 
      rightDoorOpen 3s ease-out forwards 1.5s,
      rightDoorFlap 10s linear infinite forwards 8s;
    transform-origin: 100% 0 0;
  }
}
.book {
  position: absolute;
  box-sizing: border-box;
  padding: 0.8rem 4rem 0.8rem 2rem;
  border-radius: 0.25rem;
  background-color: rgba(7, 3, 61, 0.26);
  color: rgb(6, 15, 136);
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  cursor: pointer;
  box-shadow: inset 0 0 0.75rem rgba(255, 255, 255, 0.192);
  &.home-page {
    transform: rotate(-90deg) translate(-12.4rem, 3rem);
    transform-origin: 0;
  }
  &.about-us {
    transform: rotate(-100deg) translate(-13.4rem, 6.1rem);
    transform-origin: 0;
    outline: 1px solid transparent;
  }
  &.contact {
    right: 2rem;
    bottom: 0.2rem;
    border-radius: 0.3rem 0 0 0.3rem;
  }
   &.faq {
    right: 0.8rem;
    bottom: 3.3rem;
    border-radius: 0.3rem 0 0 0.3rem;
  }
  &.not-found {
    width: 12rem;
    height: 3.5rem;
    border: 1px dashed rgba(255, 255, 255, 0.3);
    background-color: transparent;
    transform: rotate(-90deg) translate(-12rem, 13rem) scale(1);
    transform-origin: 0;
    cursor: default;
    animation: bookFadeOut 1s 3s infinite forwards;
    
    &::after {
      display: block;
      width: 10rem;
      padding-left: 5rem;
      background-repeat: no-repeat;
      background-size: 4rem;
      background-position: left center;
      font-family: "Dancing Script";
      text-transform: lowercase;
      font-size: 25px;
      content: "SORRY!!!";
      transform: rotate(90deg) translate(6rem, -1rem);
      opacity: 0.3; 
    }
  }
  &:hover:not(.not-found) {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
@keyframes leftDoorOpen {
  60% {transform: rotateY(-115deg)}
  100% {transform: rotateY(-110deg)}
}
@keyframes rightDoorOpen {
  60% {transform: rotateY(125deg)}
  100% {transform: rotateY(120deg)}
}
@keyframes rightDoorFlap {
  0% { transform: rotateY(120deg)}
  5% {transform: rotateY(125deg)}
  15% {transform: rotateY(117deg)}
  25% {transform: rotateY(123deg)}
  30% {transform: rotateY(120deg)}
  100% {transform: rotateY(120deg)}
}
@keyframes leftDoorFlap {
  0% { transform: rotateY(-110deg)}
  5% {transform: rotateY(-115deg)}
  15% {transform: rotateY(-107deg)}
  25% {transform: rotateY(-113deg)}
  30% {transform: rotateY(-110deg)}
  100% {transform: rotateY(-110deg)}
}
@keyframes bookFadeOut {
  50% {border: 1px dashed rgba(255, 255, 255, 0.1);}
}