
/* main css */

.contact-container {

  width: 100%;

  height: 100%;

  padding: 50px 0;

  background: linear-gradient(90deg,rgba(41, 58, 207, 0.363),rgb(57, 111, 230));

  display: flex;

  justify-content: center;

  align-items: center;

}

.inner-container {

  background: rgba(0, 0, 0, 0.1);

  box-shadow: 0px 0px 10px rgb(0, 0, 0);

  width: 500px;

  padding: 20px;

  border-radius: 10px;

}

.inner-container h2 {

  font-size: 30px;

  font-weight: 700;

  text-transform: uppercase;

  text-align: center;

  position: relative;

  z-index: 1;

}

.inner-container hr {

  height: 5px;

  width: 50px;

  background: rgba(0, 0, 0, 0.39);

  border-radius: 10px;

  border: none;

  text-align: center;

  margin: 10px auto 30px auto;

}

.form-row {

  display: flex;

}

.input-data {

  width: 100%;

  margin: 30px 0px;

  position: relative;

  padding: 0 20px;

}

.input-data input {

  display: block;

  height: 100%;

  width: 100%;

  background: none;

  border: none;

  outline: none;

  color: #000;

  border-bottom: 2px solid #000;

  cursor: pointer;

}

.input-data label {

  color: #000;

  position: absolute;

  bottom: 100%;

  padding: 0;

  text-transform: capitalize;

  pointer-events: none;

  transition: .3s ease;

}

.input-data input:focus ~ label {

  transform: translateY(-20px);

  font-size: 15px;

  color: rgb(0, 0, 0);

}

.input-messege {

  margin: 30px 0px;

  position: relative;

  padding: 0 20px;

}

.input-messege textarea {

  display: block;

  width: 100%;

  height: 50px;

  font-size: 15px;

  color: #000;

  background: none;

  border: none;

  outline: none;

  border-bottom: 2px solid #000;

  padding: 5px 0;

}

.input-messege label {

  position: absolute;

  bottom: 85%;

  color: #000;

  text-transform: capitalize;

  pointer-events: none;

  transition: .3s linear;

}

.input-messege textarea:focus ~ label {

  transform: translateY(-20px);

  font-size: 15px;

  color: rgb(10, 10, 10);

}

.submit-btn {

  color: #000;

  font-size: 16px;

  font-weight: 600;

  text-transform: capitalize;

  background: none;

  border: 2px solid #000;

  border-radius: 5px;

  padding: 3px 30px;

  margin: 0 20px;

  transition: .3s linear;

  position: relative;

  z-index: 1;

}

.submit-btn::after {

  content: "";

  top: 0;

  left: 0;

  height: 100%;

  width: 0%;

  background: rgba(0, 0, 0, 0.315);

  position: absolute;

  z-index: -1;

  transition: .3s linear;

}

.submit-btn:hover::after {

  width: 100%;

} 

/* responsive css */

@media screen and (max-width: 602px) {

  .inner-container {

      width: 250px;

  }

  .form-row {

      display: block;

  }

  .input-data,

  .input-messege {

      padding: 0;

      margin: 45px 0;

  }

  .input-data input,

  .input-messege textarea {

      border-bottom: 1px solid #000;

  }

  .input-data label,

  .input-messege label {

      font-size: 15px;

      font-weight: 600;

  }

  .input-data input:focus ~ label,

  .input-messege textarea:focus ~ label {

      font-size: 13px;

  }

  .submit-btn {

      width: 100%;

      margin: 0;

      border: 1px solid #000;

  }

}